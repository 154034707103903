import React from "react"
import Card from "../components/card"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Give = () => (
  <Layout>
    <div style={{ marginTop: "24px" }}>
      <SEO title="Give" />
      <div className="fill-height-or-more">
        <Card
          title="Give Now"
          to="https://pushpay.com/pay/annexchurch/recurring"
          bgColor="#49DCB1"
        />
        <Card title="Back To Home" to="/" bgColor="#FF5B5E" />
      </div>
    </div>
  </Layout>
)

export default Give
